<template>
  <div class="ShoppingCart">
    <div class="BigBox">
      <div class="Title">购物车(3)</div>
      <div class="Header">
        <div class="Input">
          <!-- <img src="../../../assets/PersonalCenter/search.png" alt="">
          <input type="text" placeholder="请输入商品标题搜索"> -->
        </div>
        <div class="Settlement">
          <div>
            <span>已选商品金额</span><span style="margin: 0 1vw;color: #E47878;">{{ sum() }}</span>
          </div>
          <div class="Button">
            结算
          </div>
        </div>
      </div>
      <div class="Content">
        <!-- 表头 -->
        <div class="MeterHead">
          <div style="flex: 3;">商品</div>
          <div style="flex: 1;margin-left:0.5vw;">单价</div>
          <div style="flex: 1.14; margin-left:-1vw;">数量</div>
          <div style="flex: 1;">金额</div>
          <div style="flex: 3;">交易操作</div>
        </div>
        <!-- 表内容 -->
        <div class="TableContent">
          <div class="MeterContent" v-for="(item, index) in ShoTro" :key="index">
            <div class="ContentName">
              <span>2022-10-10</span>
              <span>订单号：2786115544331</span>
              <span>{{ item.categoryName }}</span>
            </div>
            <div class="OrderContent">
              <div style="flex: 3;justify-content: flex-start;">
                <input type="checkbox" name="" id="color-input-red" v-model="checklist" :value="item">
                <img src="../../../assets/PersonalCenter/goods.png" style="margin: 0 2vw;width: 8vw;height: 12vh;">
                <div style="height: 80%;align-items: flex-start;color:rgba(54, 54, 54, 1);">{{ item.goodsTitle }}</div>
              </div>
              <div style="flex: 1;flex-direction: column;">
                <s style="color: #9F9F9F;font-size: 0.8vw;">￥{{ item.oringinalPrice / 100 }}</s>
                <p style="color: rgba(46, 46, 46, 1);">￥{{ item.salesPrice / 100 }}</p>
              </div>
              <div style="flex: 1;">
                <div style="background-color: #ECECEC;padding: 0 0.5vw; color: rgba(72, 72, 72, 1);cursor: pointer;"
                  @click="shear(index)">-</div>
                <div class="quantity"
                  style="width:2.5vw;height:2.3vh;text-align:center;color: rgba(38, 38, 38, 1);border:1px solid rgba(221, 221, 221, 1);">
                  {{ item.productNum }}</div>
                <!-- <input type="text"  v-model="Form.price"> -->
                <div style="background-color: #ECECEC;padding: 0 0.5vw;color: rgba(72, 72, 72, 1);cursor: pointer;"
                  @click="plus(index)">+</div>
              </div>
              <div style="flex:1;color: #E92626;">
                <p>￥{{ item.salesPrice / 100 * item.productNum }}</p>
              </div>
              <div style="flex: 3;">
                <div style="width: 30%;justify-content: space-between;color: rgba(16, 16, 16, 1);">
                  <div style="cursor: pointer;">删除</div>
                  <div style="cursor: pointer;">结算</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 购物车列表
      ShoTro: [],
      //数量
      quantity: '',
      checklist: [], // 拿到勾选了的商品
    }
  },
  created() {
    this.ShopList()
  },
  mounted() {

  },
  methods: {
    // 购物车列表请求数据
    ShopList() {

      // 从cookies里获取token
      // this.$cookies.get("token")
      var axios = require('axios');
      var config = {
        method: 'get',
        url: this.$Schttp + '/vtp/app/cart/list',
        headers: {
          Authorization: "Bearer " + this.$cookies.get("token"),
          "Content-Type": "application/json",
        },
      };
      axios(config)
        .then((res) => {
          if (res.data.code == 200) {
            this.ShoTro = res.data.data
          }
        })
        .catch(function (error) {
        });
    },
    sum() {
      var total = 0
      this.checklist.forEach(item => {
        total += item.salesPrice / 100 * item.productNum
      });
      return total
    },

    // 点击商品少一个
    shear(index) {
      this.ShoTro[index].productNum
      if (this.ShoTro[index].productNum <= 1) {
        // alert("不能在减了")
        //否则 (大于1的时候) --
      } else {
        this.ShoTro[index].productNum--
      }
    },
    plus(index) {
      this.ShoTro[index].productNum++
    },
  },
}
</script>

<style scoped lang="less">
* {
  margin: 0;
  padding: 0;
}

.ShoppingCart {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .BigBox {
    width: 94%;
    height: 94%;
    display: flex;
    flex-direction: column;

    .Title {
      color: rgba(255, 255, 255, 1);
      font-size: 2vw;
      margin-left: 3.8vw;
      margin-top: 2vh;
    }

    .Header {
      display: flex;
      width: 90%;
      margin: 5vh auto;
      justify-content: space-between;
      align-items: center;

      .Input {
        display: flex;
        border: 0px solid #B7B7B7;
        width: 35vw;
        height: 5vh;
        align-items: center;

        input {
          border: none;
          height: 100%;
          width: 90%;
          font-size: 2vh;
          background-color: rgba(255, 255, 255, 0);
          color: rgba(173, 173, 173, 1);
          text-indent: 0.5vw;
        }

        input::-webkit-input-placeholder {
          /* WebKit browsers */
          color: rgba(173, 173, 173, 1);
        }

        input:focus {
          outline: none;
        }

        img {
          height: 50%;
          margin: 0 1.5vh;
        }
      }

      .Settlement {
        height: 5vh;
        display: flex;
        // margin-right: 5vw;
        font-size: 2vh;
        align-items: center;

        .Button {
          width: 6vw;
          background-color: #E47878;
          color: #FFFFFF;
          border-radius: 24px;
          justify-content: center;
          cursor: pointer;
        }

        div {
          height: 100%;
          display: flex;
          align-items: center;
        }
      }
    }

    .Content {
      display: flex;
      width: 90%;
      height: 60vh;
      margin: 0vh auto;
      margin-top: -3vh;
      flex-direction: column;

      .MeterHead {
        width: 100%;
        height: 8vh;
        background-color: rgba(243, 243, 243, 1);
        display: flex;

        div {
          display: flex;
          height: 100%;
          justify-content: center;
          align-items: center;
          font-size: 1vw;
          color: #181818;
        }
      }

      .TableContent {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 65vh;
        margin-top: 2vh;
        overflow: hidden scroll;

        .MeterContent {
          display: flex;
          flex-direction: column;
          border: 1px solid #F2F2F2;
          width: 101%;
          height: 20vh;
          margin-bottom: 2vh;
          background-color: rgba(255, 255, 255, 1);
          flex-shrink: 0;

          .ContentName {
            height: 25%;
            width: 100%;
            background-color: #F2F2F2;
            display: flex;
            align-items: center;

            span {
              margin: 0 1vw;
              color: #000000;
              font-size: 2vh;
            }
          }

          .OrderContent {
            display: flex;
            height: 75%;
            width: 100%;
            font-size: 1vw;

            #color-input-red {
              width: 2vh;
              height: 3vh;
              cursor: pointer;
              margin: 0 1.35vw
            }

            div {
              display: flex;
              align-items: center;
              justify-content: center;

              input {
                border: 1px solid rgba(221, 221, 221, 1);
              }

              input:focus {
                outline: 1px solid #F2F2F2;
              }
            }
          }
        }
      }
    }
  }

  /* 整个滚动条 */
  ::-webkit-scrollbar {
    width: 0.3vw;
    height: 0.1vh;
    background-color: rgba(213, 213, 213, 0.5);
  }

  /* 滚动条上的滚动滑块 */
  ::-webkit-scrollbar-thumb {
    height: 0.3vh;
    background-color: rgba(213, 213, 213, 0.5);
    border-radius: 32px;
  }

}</style>